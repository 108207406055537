<template>
    <div id="info-footer">
        <div class="mt-4 lg:mt-20 lg:flex">
            <div class="lg:mr-8 w-sidemap">
                <h3 class="text-base font-medium w-sidemap">Productos</h3>
                <router-link :to="{name:'directory'}" class="block text-sm mt-8">
                    Directorio
                </router-link>
                <router-link :to="{name:'destiny'}" class="block text-sm mt-6">
                    Destino
                </router-link>
                <router-link :to="{name:'experiences'}" class="block text-sm mt-6">
                    Experiencias
                </router-link>
                <router-link :to="{name:'chat'}" class="block text-sm mt-6">
                    Chat
                </router-link>
                <router-link :to="{name:'capturing_reviews'}" class="block text-sm mt-6">
                    Captación de reseñas
                </router-link>
                <router-link :to="{name:'firewall'}" class="block text-sm mt-6">
                    Cortafuegos
                </router-link>
                <router-link :to="{name:'console_reviews'}" class="block text-sm mt-6">
                    Consola de reseñas
                </router-link>
                <router-link :to="{name:'analysis'}" class="block text-sm mt-6">
                    Análisis
                </router-link>
            </div>
            <div class="lg:mr-8 w-sidemap">
                <h3 class="text-base font-medium mt-8 lg:mt-0">Plataformas</h3>
                <router-link :to="{name:'hoster'}" class="block text-sm mt-8">
                    SaaS
                </router-link>
                <router-link :to="{name:'guest'}" class="block text-sm mt-6">
                    WebApp
                </router-link>
            </div>
            <div class="lg:mr-8 w-sidemap">
                <h3 class="text-base font-medium mt-8 lg:mt-0">Soluciones</h3>
                <router-link :to="{name:'comunication'}" class="block text-sm mt-8">
                    Comunicación
                </router-link>
                <router-link :to="{name:'reputation'}" class="block text-sm mt-6">
                    Reputación online
                </router-link>
            </div>
            <div class="w-sidemap">
                <h3 class="text-base font-medium mt-8 lg:mt-0">Empresa</h3>
                <router-link :to="{name:'contact'}" class="block text-sm mt-8">
                    Contacta con nosotros
                </router-link>
            </div>
        </div>
    </div>
    <div class="copyright-container border-t border-gray-300 mt-16 lg:mt-20">
        <!-- info-footer --> 
        <div class="text-copyright py-6">
            <div class="flex">
                <p class="text-xs hidden lg:inline mr-6 my-auto">Copyright © 2023 The Hoster. All rights reserved</p>
                <router-link :to="{name:'warning'}" class="text-xs font-medium mt-auto lg:mb-auto">
                    Aviso Legal
                </router-link>
                <img @click="go_linkedin" class="ml-auto w-6 h-6 cursor-pointer linkedin" src="/img/icons/linkedin.svg">
            </div>
            <p class="text-xs mt-4 lg:hidden">Copyright © 2023 The Hoster. All rights reserved</p>
        </div>
    </div>
</template>

<script setup>
//import libraries
// import { onMounted, ref, reactive, nextTick, inject, computed, provide, toRefs } from 'vue';
function go_linkedin(){
    window.open('https://www.linkedin.com/company/the-hoster/', '_blank');
}
//DATA
    
</script>

<style scoped>
.w-sidemap{
    width: 240px;
}
a{
    color:#333;
} 
a:hover{
    color:#34A98F;
}
.linkedin:hover{
    filter: invert(53%) sepia(92%) saturate(298%) hue-rotate(116deg) brightness(89%) contrast(88%);
}

 
.text-copyright{
    max-width: 1056px;
}

#info-footer{
	padding: 80px 112px;
  /* max-width: 1280px; */
}
.copyright-container{
    padding: 0px 112px;
}
@media (max-width:1020px) {
    .w-sidemap{
        width: 100%;
    }

    .copyright-container{
        padding: 0px 16px;
    }
  #info-footer{
    padding: 24px 16px;
  }
}
@media (min-width:1440px) {
    .text-copyright{
        max-width: 1216px;
    }
    .w-sidemap{
        width: 280px;
    }
}
@media (min-width:1920px) {
    .copyright-container{
            padding: 0 352px;
    }
    #info-footer{
            padding: 80px 352px;
    }
}
</style>