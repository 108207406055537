import WarningLegal from '@/views/legal/WarningLegal.vue';
import PrivacyLegal from '@/views/legal/PrivacyLegal.vue';
import TermsConditions from '@/views/legal/TermsConditions.vue';

const adminRoutes = [
    {
      path: '/aviso-legal',
      name: 'warning',
      component: WarningLegal
    },
    {
      path: '/privacidad',
      name: 'privacy',
      component: PrivacyLegal
    },
    {
      path: '/terminos-y-condiciones',
      name: 'termsconditions',
      component: TermsConditions
    }
  ];
  
  export default adminRoutes;
  