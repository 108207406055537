<template>
    <div class="experience-container lg:text-center">
        <h1 class="tx22 lg:text-40 font-medium leading-snug lg:leading-snug pr-6 lg:pr-0">
            Completa la experiencia, <br>
            mejora las reseñas, <br>
            aumenta tus ingresos
        </h1>
        <p class="text-sm lg:text-base font-medium mt-8 lg:mt-6">
            Aumenta tu precio en un 11.2%, la ocupación en un 14.2% y <br>
            el RevPAR en un 16.3% con una mejora de un<br>
            punto en las valoraciones en línea
        </p>
        <button @click="contact_us" class="hbtn-cta mt-10 lg:mt-12 text-base font-medium rounded-lg h-14 w-full lg:w-44">
            Te llamamos
        </button>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

function contact_us() {
    router.push({ name: 'contact' });
}
</script>


<style scoped> 
.experience-container{
    margin: 0 232px;
    width: 592px;
}
@media (max-width:1020px){

.experience-container{
    margin:0;
    width: 100%;
}
}
@media (min-width:1440px){
.experience-container{
    margin: 0 312px;
    width: 592px;
}
}
@media (min-width:1920px){
.experience-container{
    margin: 0 312px;
    width: 592px;
}
}
</style>