<template>
    <Layout>
        <section id="ic-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium lg:leading-snug w-[328px] lg:w-auto">
                    Tu guía personalizada de la ciudad
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Reúne todo lo que tu ciudad puede ofrecer en una sola plataforma. Elige los mejores monumentos, restaurantes y lugares de ocio según tus preferencias y las de tus huéspedes.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            
            <img class="image-landing mt-6 lg:mt-auto mb-auto hidden-xl" src="/img/destino-banner.jpg" alt="Tu guía personalizada de la ciudad">
            <img class="image-landing mt-6 lg:mt-auto mb-auto show-xl" src="/img/destino-banner-3x.jpg" alt="Tu guía personalizada de la ciudad">
        </section>
    
        <section id="ic-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Mejora la experiencia de tus huéspedes en la ciudad" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Enriquece la estancia de tus huéspedes
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Personaliza la guía de tu hotel según tu estilo" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Guía ajustada a la personalidad de tu hotel
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Ahorra tiempo y esfuerzo en investigación " style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Ahorra tiempo y esfuerzo en investigación 
                </p>
            </div>
        </section>
    
        <section id="ic-offer" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block" src="/img/destino-1.jpg" alt="Ofrece una guía turística completa de la ciudad">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Ofrece una guía turística completa de la ciudad
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con nuestra guía personalizada, facilitas a los huéspedes descubrir la esencia local de la ciudad, con recomendaciones exclusivas que enriquecen su estancia. <br><br>
                    Esta personalización no solo eleva la satisfacción del cliente, sino que reduce las consultas recurrentes al personal, permitiéndoles enfocarse en tareas cruciales y optimizando así el funcionamiento del hotel.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/destino-1.jpg" alt="Ofrece una guía turística completa de la ciudad">
        </section>
    
        <section id="ic-categories" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Organización por categorías para una navegación sencilla
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Los huéspedes desean acceder a la información sobre la ciudad de la forma más fácil posible. Por ello, ofrecemos un amplio catálogo de información turística dividida en diferentes categorías
                </p>
                <div class="lg:mt-8 grid grid-cols-1 lg:grid-cols-2">
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Qué visitar
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4 lg:pr-8">
                            Ayuda a tus huéspedes a descubrir los puntos de mayor interés, incluidos monumentos, museos, parques y jardines.
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Dónde comer
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Recomienda los mejores lugares para disfrutar de la gastronomía local, desde cafeterías hasta bares de tapas y restaurantes.
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-8">
                            Ocio
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Sugiere opciones de entretenimiento para que tus huéspedes disfruten al máximo de su tiempo libre en la ciudad, como discotecas, bares de copas o centros comerciales.
                        </p>
                    </div>
                </div>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/destino-2.jpg" alt="Organización por categorías para una navegación sencilla">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/destino-2-3x.jpg" alt="Organización por categorías para una navegación sencilla">
        </section>
    
        <section id="ic-guide" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/destino-3.jpg" alt="Una guía adaptada a tu estilo">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/destino-3-3x.jpg" alt="Una guía adaptada a tu estilo">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium pr-20 leading-8 lg:leading-snug">
                    Listo para usar, fácil de personalizar
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con Destino, te entregamos una guía turística "llave en mano" ya repleta de sugerencias y recomendaciones locales para que tus huéspedes puedan empezar a disfrutar desde el primer momento. <br><br>
    Además, podrás personalizarla fácilmente, destacando los lugares que consideres más acorde con la personalidad de tu hotel y los gustos de tus huéspedes. Una herramienta intuitiva y de fácil manejo que te permite mantener el foco en brindar una experiencia inolvidable.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/destino-3.jpg" alt="Una guía adaptada a tu estilo">
            
        </section>
    
        <section id="ic-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        //imports components
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    
    </script>