<template>
  <div class="flex justify-center items-center mt-[40px] 2xl-1440:mt-[15px] pr-[120px] pl-[120px] 2xl-1440:pr-[140px] 2xl-1440:pl-[140px]">
    <div class="grid grid-cols-3 gap-[64px]">
      <!-- Columna WebApp -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[35px] 2xl-1440:text-[21px] custom-span">WebApp</h1>
        <hr class="border border-2 w-[450px] border-black">
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/icons/2.TH.Item_icon_Card_Pricing.svg" alt="Icono Alojamiento" class="w-[64px] h-[64px] mr-4">
          <h1 class="text-[29px] font-semibold 2xl-1440:text-[20px]">Alojamiento</h1>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/destino.svg" alt="Icono Destino" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Destino</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/experiencias.svg" alt="Icono Experiencias" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Experiencias</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/suite.svg" alt="Icono Suite de comunicación" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Chat</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/seguimiento.svg" alt="Icono Seguimiento" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Seguimiento</p>
        </div>
      </div>

      <!-- Columna Hoster -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[35px] 2xl-1440:text-[21px] custom-span">Hoster</h1>
        <hr class="border border-2 w-[450px] border-black">
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/gestion.svg" alt="Icono Gestión de reseñas" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Reseñas</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/estancias2.png" alt="Icono Gestión de estancias" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Estancias</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/2.TH.Item_icon_Card_Pricing.png" alt="Icono Gestión de estancias" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Comunicaciones</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] relative">
          <img src="/img/presentacion/webapp.svg" alt="Icono Webapp Personalizada" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Personalización</p>
        </div>
      </div>

      <!-- Columna Atención al cliente -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[35px] 2xl-1440:text-[21px] custom-span">Atención al cliente</h1>
        <hr class="border border-2 border-black">
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/4.svg" alt="Icono Gestión de reseñas" class="w-[64px] p-1 rounded-[6px] bg-[#34A98F] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Atención 24/7</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/1.svg" alt="Icono Gestión de estancias" class="p-1 rounded-[6px] bg-[#34A98F] w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Implementación <br> en menos de 24hs</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/2.svg" alt="Icono Gestión de estancias" class="p-1 rounded-[6px] bg-[#34A98F] w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Capacitación completa</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] relative">
          <img src="/img/presentacion/3.svg" alt="Icono Webapp Personalizada" class="p-1 rounded-[6px] bg-[#34A98F] w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Personalización del servicio </p>
        </div>
        <div class="flex flex-col mt-3">
          <span class="text-[29px] font-semibold 2xl-1440:text-[20px] text-right custom-span">
            <span class="text-[34px] custom-span">8</span>.99€
          </span>
          <span class="text-[28px] font-semibold text-right custom-span">Por habitación al mes</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-span {
  color: inherit !important;
  font-family: 'Rethink Sans', sans-serif !important;
}
</style>
