<template>
    <div class="w-full bg-white lg:fixed z-50 left-0 pt-8 pb-4">
        <div class="spacing-header-titles flex">
            <div class="h-[66px] hborder-bottom-gray-400 w-titles py-6 mr-titles">
                <p class="text-base font-medium leading-4">
                    Comunicación
                </p>
            </div>
            <div class="h-[66px] hborder-bottom-gray-400 w-titles py-6">
                <p class="text-base font-medium leading-4">
                    Reputación Online
                </p>
            </div>
        </div>
        <div class="flex spacing-links">
            <div class="w-section-link hover-boxlink flex-shrink-0 mr-section-links">
                <div class="flex justify-between mt-4">
                    <router-link :to="{name:'directory'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/header-info-product-hotel.svg" alt="Directorio">
                        <div>
                            <h4 class="text-sm font-semibold">Directorio</h4>
                            <p class="text-sm mt-2">Todo sobre tu hotel, en una sola plataforma</p>
                        </div>
                    </router-link>
                    <router-link :to="{name:'experiences'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/header-info-product-experiences.svg" alt="Experiencias">
                        <div>
                            <h4 class="text-sm font-semibold">Experiencias</h4>
                            <p class="text-sm mt-2">Experiencias únicas en la ciudad</p>
                        </div>
                    </router-link>
                </div>
                <div class="flex justify-between mt-4">
                    <router-link :to="{name:'destiny'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.GUÍACIUDADNavBar.svg" alt="Destino">
                        <div>
                            <h4 class="text-sm font-semibold">Destino</h4>
                            <p class="text-sm mt-2">Tu guía personalizada de la ciudad</p>
                        </div>
                    </router-link>
                    <router-link :to="{name:'chat'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.Chat.svg" alt="Chat">
                        <div>
                            <h4 class="text-sm font-semibold">Chat</h4>
                            <p class="text-sm mt-2">Comunicación directa con tus huéspedes</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="w-section-link hover-boxlink flex-shrink-0">
                <div class="flex justify-between mt-4">
                    <router-link :to="{name:'capturing_reviews'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.Captaciónreseñas.svg" alt="Captación de reseñas">
                        <div>
                            <h4 class="text-sm font-semibold">Captación de reseñas</h4>
                            <p class="text-sm mt-2">Maximiza las reseñas positivas</p>
                        </div>
                    </router-link>
                    <router-link :to="{name:'console_reviews'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.Consoladereseñas.svg" alt="Consola de reseñas">
                        <div>
                            <h4 class="text-sm font-semibold">Consola de reseñas</h4>
                            <p class="text-sm mt-2">Centraliza las reseñas de tu alojamiento</p>
                        </div>
                    </router-link>
                </div>
                <div class="flex justify-between mt-4">
                    <router-link :to="{name:'firewall'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.Cortafuegos.svg" alt="Cortafuegos">
                        <div>
                            <h4 class="text-sm font-semibold">Cortafuegos</h4>
                            <p class="text-sm mt-2">Reduce las reseñas negativas</p>
                        </div>
                    </router-link>
                    <router-link :to="{name:'analysis'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.ANALISIS.svg" alt="Chat">
                        <div>
                            <h4 class="text-sm font-semibold">Análisis</h4>
                            <p class="text-sm mt-2">Analiza tu reputación online</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped> 
a:hover h4{
    color: #34A98F;
}
.hover-boxlink a:hover {
    background-color: #ECF9F5;
}

.spacing-header-titles{
	padding: 0 112px;
}
.mr-titles{
    margin-right: 48px;    
}
.w-titles{
    width: 504px;    
}

.spacing-links{
	padding: 0 104px;
}
.w-link{
    width: 252px;
}
.mr-section-links{
    margin-right: 32px;
}
.w-section-link{
    width: 520px;    
}
@media (min-width:1440px) {
    .mr-titles{
        margin-right: 32px;    
    }
    .w-titles{
        width: 592px;    
    }

    .w-link{
        width: 288px;
    }
    .mr-section-links{
        margin-right: 16px;
    }
    .w-section-link{
        width: 608px;    
    }
}
@media (min-width:1920px) {
    .spacing-links{
        padding: 0 344px;
    }
    .spacing-header-titles{
        padding: 0 352px;
    }
}
@media (max-width:1020px) {
  .spacing-links{
    padding: 0 16px;
  }
}
</style>