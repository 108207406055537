<template>
    <Layout>
        <section id="comu-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="width-info mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Conecta y comunícate con tus huéspedes
                </h1>
                <p class="text-base lg:text-22xl font-medium mt-8 lg:leading-8">
                    Anticípate a las necesidades de tus huéspedes con una WebApp sin descargas, facilitando una comunicación adaptada a las preferencias digitales actuales.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="width-image mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/comu-banner.jpg" alt="Conecta y comunícate con tus huéspedes">
            <img class="width-image mt-8 lg:mt-auto mb-auto show-xl" src="/img/comu-banner-3x.jpg" alt="Conecta y comunícate con tus huéspedes">
        </section>
    
        <section id="comu-interest" class="flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Conexión con el huésped desde antes de la estancia" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Conexión con el huésped desde antes de la estancia
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Chat e información completa en un solo lugar" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Chat e información completa en un solo lugar
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Mejora la comunicación y experiencia del huésped" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Mejora la comunicación y experiencia del huésped
                </p>
            </div>
        </section>
        
        <section id="comu-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="width-info my-auto mr-landing">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Beneficios
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con nuestra solución, la comunicación con tus huéspedes es integral, desde antes de su llegada hasta después de su partida. Esta conexión mejora su experiencia y optimiza tu tiempo, evitando repetir respuestas a preguntas comunes.  <br> <br>
                    Además, una comunicación efectiva eleva tus valoraciones en OTAs, fortaleciendo tu reputación online, todo mientras brindas una experiencia moderna y sin fricciones para tus huéspedes. 
                </p>
            </div>
            <img class="width-image mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/comu-1.jpg" alt="Beneficios">
            <img class="width-image mt-8 lg:mt-auto mb-auto show-xl" src="/img/comu-1-3x.jpg" alt="Beneficios">
        </section>

        <section id="comu-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="width-image my-auto hidden lg:inline mr-landing hidden-xl" src="/img/comu-2.jpg" alt="Tu alojamiento al detalle">
            <img class="width-image my-auto hidden lg:inline mr-landing show-xl" src="/img/comu-2-3x.jpg" alt="Tu alojamiento al detalle">
            <div class="width-info my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Tu alojamiento al detalle
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Los huéspedes a menudo llegan con expectativas erróneas sobre el alojamiento, basadas en información inexacta o desactualizada. <br> <br>
                    Proporciona un perfil detallado y actualizado del hotel, desde datos básicos como dirección y horarios, hasta un recorrido completo por las instalaciones, asegurando que los huéspedes sepan exactamente qué esperar antes de su llegada.
                </p>
                <router-link :to="{name:'directory'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Directorio
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Directorio">
                </router-link>
            </div>
            <img class="width-image mt-8 lg:hidden" src="/img/comu-2.jpg" alt="Tu alojamiento al detalle">
        </section>
    
        <section id="comu-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="width-info my-auto mr-landing">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Muestra lo mejor de la ciudad
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Evita que tus huéspedes se sientan perdidos o desinformados al explorar la ciudad. <br> <br>
                    Proporciona una guía integral con las mejores recomendaciones, desde monumentos históricos y museos hasta las mejores opciones de ocio y gastronomía, todo en un solo lugar.
                </p>
                <router-link :to="{name:'destiny'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Destino
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Destino">
                </router-link>
            </div>
            <img class="width-image mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/comu-3.jpg" alt="Muestra lo mejor de la ciudad">
            <img class="width-image mt-8 lg:mt-auto mb-auto show-xl" src="/img/comu-3-3x.jpg" alt="Muestra lo mejor de la ciudad">
        </section>

        <section id="comu-section-4" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="width-image my-auto hidden lg:inline mr-landing hidden-xl" src="/img/comu-4.jpg" alt="Actividades a medida para tus huéspedes">
            <img class="width-image my-auto hidden lg:inline mr-landing show-xl" src="/img/comu-4-3x.jpg" alt="Actividades a medida para tus huéspedes">
            <div class="width-info my-auto">
                <h1 class="tx22 lg:text-40 font-medium eading-8 lg:leading-snug">
                    Actividades a medida para tus huéspedes
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Los huéspedes buscan completar su estancia con actividades turísticas que se ajusten a sus intereses. No obstante, esa búsqueda puede ser abrumadora y requerir más tiempo del deseado. <br> <br>
                    Facilita a tus huéspedes un catálogo de experiencias, actualizadas y de calidad, sin que tengas que invertir tiempo ni esfuerzo en su búsqueda y selección.
                </p>
                <router-link :to="{name:'experiences'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Experiencias
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Experiencias">
                </router-link>
            </div>
            <img class="width-image mt-8 lg:hidden" src="/img/comu-4.jpg" alt="Actividades a medida para tus huéspedes">
        </section>

        <section id="comu-section-5" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="width-info my-auto mr-landing">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Chat directo con tus huéspedes
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    La falta de comunicación adecuada puede llevar a malentendidos, insatisfacción y, en última instancia, a valoraciones negativas. Además, los huéspedes esperan respuestas rápidas y soluciones inmediatas a sus inquietudes.  <br> <br>
                    Utiliza el chat para atender inquietudes en tiempo real, antes, durante y después de la estancia. Podrás utilizar respuestas predefinidas automáticas para momentos de alta actividad y recibir notificaciones de mensajes pendientes, asegurando que ninguna consulta quede sin respuesta.
                </p>
                <router-link :to="{name:'chat'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Chat
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Chat">
                </router-link>
            </div>
            <img class="width-image mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/comu-5.jpg" alt="Chat directo con tus huéspedes">
            <img class="width-image mt-8 lg:mt-auto mb-auto show-xl" src="/img/comu-5-3x.jpg" alt="Chat directo con tus huéspedes">
        </section>

        <section id="comu-section-6" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="width-image my-auto hidden lg:inline mr-landing hidden-xl" src="/img/comu-6.jpg" alt="Listo para utilizar">
            <img class="width-image my-auto hidden lg:inline mr-landing show-xl" src="/img/comu-6-3x.jpg" alt="Listo para utilizar">
            <div class="width-info my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Listo para utilizar
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Preparamos toda la información de tu alojamiento, la ciudad y las actividades turísticas, para que la tengas disponible desde el primer momento. Puedes personalizar aún más esa información, destacando lugares de la ciudad o experiencias. Además, puedes elegir las respuestas automáticas del chat, según tus necesidades. <br> <br>
                    A través de la WebApp, tus huéspedes encontrarán toda esta información y podrán comunicarse contigo directamente a través del chat, mejorando su experiencia global.
                </p>
            </div>
            <img class="width-image mt-8 lg:hidden" src="/img/comu-6.jpg" alt="Listo para utilizar">
        </section>

        <section id="ie-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
    
    <style scoped> 

    #comu-interest{
        width: 1056px;
    }

    #comu-section-6 .width-info,
    #comu-section-5 .width-info,
    #comu-section-4 .width-info,
    #comu-section-3 .width-info,
    #comu-section-2 .width-info,
    #comu-section-1 .width-info,
    #comu-banner .width-info{
        width: 512px;
    }

    #comu-section-6 .width-image,
    #comu-section-5 .width-image,
    #comu-section-4 .width-image,
    #comu-section-3 .width-image,
    #comu-section-2 .width-image,
    #comu-section-1 .width-image,
    #comu-banner .width-image{
        width: 421px;
    }

    @media (max-width:1020px){
        
        #comu-interest,
        #comu-section-6 .width-info,
        #comu-section-5 .width-info,
        #comu-section-4 .width-info,
        #comu-section-3 .width-info,
        #comu-section-2 .width-info,
        #comu-section-1 .width-info,
        #comu-banner .width-info,
        #comu-section-1 .width-image,
        #comu-section-2 .width-image,
        #comu-section-1 .width-image,
        #comu-banner .width-image{
            width: auto;
        }
    }
    @media (min-width:1440px){
        #comu-interest{
            width: 1216px;
        }
        #comu-section-6 .width-info,
        #comu-section-5 .width-info,
        #comu-section-4 .width-info,
        #comu-section-3 .width-info,
        #comu-section-2 .width-info,
        #comu-section-1 .width-info,
        #comu-banner .width-info{
            width: 592px;
        }
        #comu-section-6 .width-image,
        #comu-section-5 .width-image,
        #comu-section-4 .width-image,
        #comu-section-3 .width-image,
        #comu-section-2 .width-image,
        #comu-section-1 .width-image,
        #comu-banner .width-image{
            width: 488px;
        }   
    }
    </style>
   