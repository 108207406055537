<template>
    <div class="contact-form mx-auto lg:p-6 rounded-lg sticky top-6 left-0">
      <div>
          <label :class="{'color-error':errorsKey.includes('name')}" class="mb-2 text-base font-medium block">
              Nombre completo <span>*</span>
          </label>
          <input 
              type="text" 
              v-model="form.name" 
              :class="{
                  'border-dark':form.name,
                  'input-error':errorsKey.includes('name')
              }"
              class="border block rounded-lg h-12 w-full text-sm px-3 py-2" 
              placeholder="Escribe tu nombre"
              @click="clean_error('name')"
          >
      </div>
      <div class="mt-4">
          <label :class="{'color-error':errorsKey.includes('email')}" class="mb-2 text-base font-medium block">
              Email <span>*</span>
          </label>
          <input 
              type="text" 
              v-model="form.email" 
              :class="{
                  'border-dark':form.email,
                  'input-error':errorsKey.includes('email')
              }"
              class="border block rounded-lg h-12 w-full text-sm px-3 py-2" 
              placeholder="Escribe tu email"
              @click="clean_error('email')"
          >
      </div>
      <div class="mt-4">
          <label :class="{'color-error':errorsKey.includes('phone')}" class="mb-2 text-base font-medium block">
              Teléfono <span>*</span>
          </label>
          <input 
              type="text" 
              v-model="form.phone" 
              :class="{
                  'border-dark':form.phone,
                  'input-error':errorsKey.includes('phone')
              }"
              class="border block rounded-lg h-12 w-full text-sm px-3 py-2" 
              placeholder="Escribe tu teléfono"
              @click="clean_error('phone')"
          >
      </div>
      <div class="mt-4">
          <label class="mb-2 text-base font-medium block">Algo más que nos quieras contar</label>
          <textarea 
              type="text" v-model="form.more" 
              class="border block rounded-lg h-28 w-full text-sm px-3 py-2" 
              :class="{'border-dark':form.more}"
              placeholder="Encantado de tener una charla sobre el producto"
          >
          </textarea>
      </div>
      <div class="mt-4 flex">
        <!-- rounded-6 -->
          <Checkbox 
            v-model="form.terms" 
            @click="clean_error('terms')"
            :classes="{
                'w-[18px] h-[18px] border rounded-6 my-auto flex-shrink-0 cursor-pointer':true,
                'input-error':errorsKey.includes('terms')
            }"
        />
          <p class="pl-4 text-sm">
              Acepto los 
              <router-link :to="{name:'termsconditions'}" style="color: #34A98F;">
                  Terminos y Condiciones 
              </router-link>
              y la
              <router-link :to="{name:'privacy'}" style="color: #34A98F;">
                Política de Privacidad
              </router-link> 
          </p>
      </div>
      <div class="mt-4">
          <button 
                v-if="!load_form"
              @click="submit"
              class="w-full h-8 lg:h-10 w-16 lg:w-20 rounded-lg text-sm font-medium" 
              :class="form.name && form.phone && form.email && form.terms ? 'hbtn-cta':'bg-gray-300 text-gray-400'"
          >
              Enviar
          </button>
          <span 
            v-else
              class="h-8 lg:h-10 w-16 lg:w-20 rounded-lg text-sm"  style="color: #333;"
          >
                Enviando ...
          </span>

      </div>
    </div>
  </template>
  <script setup>
      import { ref, onMounted, reactive, inject } from 'vue';
      import Checkbox from '@/components/CheckboxCustom.vue'
      import axios from 'axios';

      axios.defaults.headers.common['x-key-api'] = process.env.VUE_APP_X_KEY_API;

      const toast = inject('toast');
      let errorsKey = ref([]);
      const route_api = ref([]);
      const load_form = ref(false);
      //data
      const form = reactive({
            name: null,
            email: null,
            phone: null,
            more: null,
            terms: false,
        });
      onMounted(()=>{
            route_api.value = process.env.VUE_APP_ROUTE_API
        })
  
      function submit(){
          valid_form();
          if(errorsKey.value.length == 0){
            load_form.value = true;
            console.log('se mando')
            axios.post(route_api.value+'/api/send-message-to-thehoster',{
                data: form
            })
            .then(response => {
                console.log(response.data)
                if(response.data.type == 'success'){
                    toast.success(response.data.message, { position: "top-right"});
                }else{
                    toast.error(response.data.message, { position: "top-right"});
                }   
                
            })
            .catch(error => {
                console.error(error);
            })
            .finally(()=>{
                clean_form();
                load_form.value = false;
            })
          }
      }
  
      function valid_form(){
          form.name ? clean_error('name') : errorsKey.value.push('name');
          form.email ? clean_error('email') : errorsKey.value.push('email');
          form.phone ? clean_error('phone') : errorsKey.value.push('phone');
          form.terms ? clean_error('terms') : errorsKey.value.push('terms');
          console.log('errorsKey',errorsKey.value)
      }
      function clean_form(){
        form.name = null;
        form.email = null;
        form.phone = null;
        form.more = null;
        form.terms = false;
      }
  
      function clean_error(name){
          errorsKey.value = errorsKey.value.filter((item) => item !== name)
      }
  </script>
  
  <style scoped>
  textarea:hover,
  input[type="text"]:hover{
      border: 1px solid #34A98F !important;
  }
  a:hover{
      color:#0B6357 !important;
      text-decoration: none !important;
  }
  .color-error,
  .input-error::placeholder{
      color:#FF6666;
  }
  .input-error{
      border: 1px solid #FF6666 !important;
  }
  span{
      color:#FFD453;
  }
  @media (min-width:1020px){
      .contact-form{
          width: 421px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
  }
  
  @media (min-width:1440px) {
      .contact-form{
          width: 488px;
      }
  }


  #i-checkbox:checked {
        background-color: red;
        border-radius: 3px;
        width: 16px;
        height: 16px;
        border: 1px solid red;
    }

    #i-checkbox:hover {
        border-color: red;
    }

  </style>