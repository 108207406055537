<template>
    <Transition>
	<div 
        v-if="open" 
        class="fixed top-0 left-0 lg:static bg-white w-[85%] h-screen z-40 pb-6 overflow-y-auto"
    >
        <!-- PRODUCT MENU -->
        <div>
            <div class="pl-6 pb-[14px] pt-[30px]" :class="{'hbg-green-200':open_products}" @click="toggle_products_menu">
                <button 
                    class="flex text-base font-semibold leading-[110%]"
                >
                    Productos
                    <img 
                        :src="open_products ? url_icons+'line_dropdown_up.svg' : url_icons+'line_dropdown.svg'" 
                        class="w-4 h-4 ml-1"
                    >
                </button>
            </div>
            <div class="relative">
                <div class="hborder-bottom-gray-400 w-[77%] absolute right-0"></div>
            </div>

            <Transition 
                @before-enter="beforeEnter" 
                @enter="enter" 
                @leave="leave"
            >
                <div v-if="open_products" id="product-mobile-menu">
                    <p class="text-base font-medium pl-6 py-[14px]">Comunicación</p>
                    <div class="relative">
                        <div class="hborder-bottom-gray-400 w-[77%] absolute right-0"></div>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'directory'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.icon.instalaciones.svg'" alt="Directorio">
                            Directorio
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'destiny'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'TH.huesped.icono.navbar.explorar-1.svg'" alt="Destino">
                            Destino
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'chat'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.ChatBubbleOUTLINEBLACK.svg'" alt="Chat">
                            Chat
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'experiences'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.CAMERA.svg'" alt="Experiencias">
                            Experiencias
                        </router-link>
                    </div>
                    <p class="text-base font-medium pl-6 py-[14px]">Reputación Online</p>
                    <div class="relative">
                        <div class="hborder-bottom-gray-400 w-[77%] absolute right-0"></div>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'capturing_reviews'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.REVIEWOUTLINEBLACK.svg'" alt="Captación de reseñas">
                            Captación de reseñas
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'firewall'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.SHIELD.svg'" alt="Cortafuegos">
                            Cortafuegos
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'console_reviews'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.AutomaticMessage.svg'" alt="Consola de reseñas">
                            Consola de reseñas
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'analysis'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.ANALISIS.svg'" alt="Análisis">
                            Análisis
                        </router-link>
                    </div>
                </div>
            </Transition>
        </div>


        <!-- SOLUTIONS MENU -->
        <div>
            <div class="pl-6 py-[14px]" :class="{'hbg-green-200':open_solutions}" @click="open_solutions_menu">
                <button 
                    class="flex text-base font-semibold leading-[110%]"
                >
                    Soluciones
                    <img 
                        :src="open_solutions ? url_icons+'line_dropdown_up.svg' : url_icons+'line_dropdown.svg'" 
                        class="w-4 h-4 ml-1"
                    >
                </button>
            </div>
            <div class="relative">
                <div class="hborder-bottom-gray-400 w-[77%] absolute right-0"></div>
            </div>
            <Transition 
                @before-enter="beforeEnter" 
                @enter="enter" 
                @leave="leave"
            >
                <div v-if="open_solutions" id="solution-mobile-menu">
                    <p class="text-base font-medium pl-6 py-[14px]">Propuestas de valor</p>
                    <div class="relative">
                        <div class="hborder-bottom-gray-400 w-[77%] absolute right-0"></div>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'comunication'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.COMUNICACION.svg'" alt="Comunicación">
                            Comunicación
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'reputation'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.REPUTACION ONLINE.svg'" alt="Reputación online">
                            Reputación online
                        </router-link>
                    </div>

                    <p class="text-base font-medium pl-6 py-[14px]">Plataformas</p>
                    <div class="relative">
                        <div class="hborder-bottom-gray-400 w-[77%] absolute right-0"></div>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'hoster'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.COMPUTER.svg'" alt="Saas Hoster">
                            SaaS
                        </router-link>
                    </div>
                    <div class="pt-[8px]">
                        <router-link :to="{name:'guest'}" class="py-[3px] pl-6 flex items-center text-sm font-medium">
                            <img class="w-6 h-6 mr-6" :src="url_hoster_icons+'1.TH.MOBILE.svg'" alt="Webapp Huéspedes">
                            WebApp
                        </router-link>
                    </div>
                </div>
            </Transition>
        </div>


        <!-- CONTACT BUTTON -->
        <div class="pt-8 px-6">
            <button @click="contact_us" class="hbtn-cta text-sm font-medium rounded-6 leading-[110%] w-full py-3 text-center">
                Contáctanos
            </button>
        </div>
        
       

      
	</div>
    </Transition>
    <div v-if="open"
        class="overlay-nav fixed z-30 h-screen w-full" style="background: rgba(0, 0, 0, 0.5);"
        @click="handleClose"
    >
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
    defineProps({
        open: {
            type: Boolean,
            default: false,
        }
    })
    
    const emit = defineEmits(['close'])
    const url_icons = '/img/icons/';
    const url_hoster_icons = '/img/icons/';
    const open_products = ref(false)
    const open_solutions = ref(false)
    const router = useRouter();
    const handleClose = () => {
        emit('close')
    }
    const contact_us = () => {
        router.push({ name: 'contact' });
    }
    const toggle_products_menu = () => {
        open_products.value = !open_products.value;
        open_solutions.value = false;
    }
    const open_solutions_menu = () => {
        open_solutions.value = !open_solutions.value;
        open_products.value = false;
    }
    
    // transición lista 
    const beforeEnter = (el) => {
        el.style.height = '0';
        el.style.overflow = 'hidden';
    }

    const enter = (el, done) => {
        el.style.height = 'auto';
        const height = el.scrollHeight + 'px';
        el.style.height = '0';

        setTimeout(() => {
            el.style.height = height;
        }, 0);

        el.addEventListener('transitionend', () => {
            el.style.overflow = '';
            done();
        }, { once: true });
    }

    const leave = (el, done) => {
        // No hacemos nada en la función leave para evitar la transición al cerrar
        done();
    }
     

</script>

<style scoped> 
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
#solution-mobile-menu,
#product-mobile-menu {
 /* transition: height 0.5s ease;
  overflow: hidden;*/
}
a:hover{
    color:initial;
}
a:focus{
    background-color:var(--h-green-200);
}

</style>