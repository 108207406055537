<template>
    <Layout>
        <section id="hoster-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] lg:mt-4 lg:w-auto">
                    Mejora la experiencia de tus huéspedes
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Soluciona todas las necesidades de tus huéspedes desde una única plataforma y mejora su estancia en tu alojamiento.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/guest-banner.jpg" alt="Mejora la experiencia de tus huéspedes">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/guest-banner-3x.jpg" alt="Mejora la experiencia de tus huéspedes">
        </section>
    
        <section id="hoster-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Información completa" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Información completa
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Comunicación directa" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Comunicación directa
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Fomenta reseñas positivas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Fomenta reseñas positivas
                </p>
            </div>
        </section>
    
        <section id="hoster-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/guest-1.jpg" alt="WebApp única del hotel">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/guest-1-3x.jpg" alt="WebApp única del hotel">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    WebApp única del hotel
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Una WebApp es una aplicación móvil que no requiere descargarse para ser utilizada. La entregamos totalmente personalizada para tu hotel y la preparamos para su uso en menos de un día, pudiendo modificarse según las preferencias del alojamiento en cualquier momento.
                </p>
                <p class="text-sm lg:text-base font-medium mt-4">
                    Los huéspedes tendrán acceso a todas las funcionalidades que necesitan directamente en sus dispositivos, facilitando la comunicación y mejorando su experiencia global.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/guest-1.jpg" alt="WebApp única del hotel">
        </section>
    
        <section id="hoster-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Fácil acceso para los huéspedes
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Podrás compartir el acceso a la WebApp con tus huéspedes desde el momento de la reserva mediante emails y SMS a través de los siguientes tres métodos:
                </p>
                <ul class="mt-4 pl-4">
                    <li class="list-disc text-sm lg:text-base font-medium">Automático: conectándonos al PMS, importamos cada reserva.</li>
                    <li class="list-disc text-sm lg:text-base font-medium">Semiautomático: importa un archivo con las reservas de tu alojamiento.</li>
                    <li class="list-disc text-sm lg:text-base font-medium">Manual: registra las reservas en nuestro SaaS, creando Estancias y añadiendo huéspedes a ellas.</li>
                </ul>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/guest-2.jpg" alt="Fácil acceso para los huéspedes">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/guest-2-3x.jpg" alt="Fácil acceso para los huéspedes">
        </section>
    
        <section id="hoster-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/guest-3.jpg" alt="Señalética para acceder a la WebApp">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/guest-3-3x.jpg" alt="Señalética para acceder a la WebApp">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Señalética para acceder a la WebApp
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Ofrecemos distintas gamas y diseños de señalética para ofrecerle a los huéspedes entrar en la WebApp durante su estancia. Estos displays físicos se podrán poner por todas las instalaciones del hotel, incluidas las habitaciones. 
                </p>
                <p class="text-sm lg:text-base font-medium mt-4">
                    Los huéspedes solo tendrán que escanear el código QR y podrán disfrutar de todos los beneficios de la WebApp. 
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/guest-3.jpg" alt="Señalética para acceder a la WebApp">
        </section>

        <section id="hoster-section-4" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Mejor comunicación, mejor experiencia
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    La WebApp está diseñada para brindar una comunicación más fluida y una mejor experiencia a los huéspedes, antes, durante y tras su estancia. En la WebApp, los huéspedes encontrarán:
                </p>
                <router-link :to="{name:'comunication'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                    Más sobre Solución de Comunicación
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Solución de Comunicación">
                </router-link>
                <div class="lg:mt-8 grid grid-cols-1 lg:grid-cols-2">
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Directorio
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4 lg:pr-8">
                            Información detallada del alojamiento
                        </p>
                        <router-link :to="{name:'directory'}" class="mt-4 text-sm lg:text-base font-medium ghost-green-600 flex">
                            Más sobre Directorio
                            <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Directorio">
                        </router-link>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Destino
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Guía de la ciudad y recomendaciones
                        </p>
                        <router-link :to="{name:'destiny'}" class="mt-4 text-sm lg:text-base font-medium ghost-green-600 flex">
                            Más sobre Destino
                            <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Destino">
                        </router-link>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-8">
                            Experiencias
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4 lg:pr-8">
                            Reserva de actividades turísticas
                        </p>
                        <router-link :to="{name:'experiences'}" class="mt-4 text-sm lg:text-base font-medium ghost-green-600 flex">
                            Más sobre Experiencias
                            <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Experiencias">
                        </router-link>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-8">
                            Chat
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Comunicación directa y en tiempo real
                        </p>
                        <router-link :to="{name:'chat'}" class="mt-4 text-sm lg:text-base font-medium ghost-green-600 flex">
                            Más sobre Chat
                            <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Chat">
                        </router-link>
                    </div>
                </div>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/guest-4.jpg" alt="Mejor comunicación, mejor experiencia">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/guest-4-3x.jpg" alt="Mejor comunicación, mejor experiencia">
        </section>

        <section id="hoster-section-5" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/guest-5.jpg" alt="Mejor experiencia, mejores reseñas">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/guest-5-3x.jpg" alt="Mejor experiencia, mejores reseñas">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Mejor experiencia, mejores reseñas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Al finalizar la estancia, utilizamos un sistema de filtrado que evalúa la satisfacción del huésped. Solo a aquellos huéspedes que han tenido una experiencia satisfactoria se les invita a compartir sus opiniones en las OTAs.
                </p>
                <p class="text-sm lg:text-base font-medium mt-4">
                    Para evitar reseñas negativas, el chat de la WebApp permite detectar y atender problemas a tiempo, proporcionando soluciones adecuadas que mejoren la experiencia del huésped.
                </p>
                <p class="text-sm lg:text-base font-medium mt-4">
                    Esta combinación de atención proactiva y reactiva contribuye a una mejor reputación online que, aumentando la demanda, genera una mayor ocupación y un aumento de los ingresos por habitación.
                </p>
                <router-link :to="{name:'reputation'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                    Más sobre Solución de Reputación Online
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Solución de Reputación Online">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/guest-5.jpg" alt="Mejor experiencia, mejores reseñas">
        </section>

        <section id="hoster-section-6" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Mejora la estancia, aumenta las reseñas online
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Un estudio de 2019 muestra que las propiedades hoteleras con una calificación de 4 estrellas o más en línea recibían un 300% más de reservas que las propiedades con calificaciones más bajas. Una experiencia personalizada se traduce en mejores valoraciones y reseñas para tu hotel. 
                </p>
                <p class="text-sm lg:text-base font-medium mt-4">
                    Consigue un mejor posicionamiento de tu negocio gracias a todos los servicios que ofrecemos en The Hoster.
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/guest-6.jpg" alt="Mejora la estancia, aumenta las reseñas online">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/guest-6-3x.jpg" alt="Mejora la estancia, aumenta las reseñas online">
        </section>
    
        <section id="hoster-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
   