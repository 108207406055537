<template>
    <Layout>
        <section id="repu-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Centraliza las reseñas de tu alojamiento
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    El 70% de los huéspedes esperan una respuesta a su reseña en menos de 24 horas. Centraliza las reseñas de las principales OTAs en una única plataforma y optimiza tu gestión del feedback.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/repu-banner.jpg" alt="Centraliza las reseñas de tu alojamiento">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/repu-banner-3x.jpg" alt="Centraliza las reseñas de tu alojamiento">
        </section>
    
        <section id="repu-interest" class="flex flex-col lg:flex-row items-center mt-16 lg:mt-20 interest">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Visión general de tu reputación online" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Visión general de tu reputación online
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Gestiona el feedback de manera más eficiente" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Gestiona el feedback de manera más eficiente
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Mantente actualizado sin esfuerzo" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Mantente actualizado sin esfuerzo
                </p>
            </div>
        </section>
    
        <section id="repu-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="mr-landing image-landing my-auto hidden lg:block  hidden-xl" src="/img/repu-1.jpg" alt="Reduce el tiempo de la gestión de reseñas">
            <img class="mr-landing image-landing my-auto hidden lg:block  show-xl" src="/img/repu-1-3x.jpg" alt="Reduce el tiempo de la gestión de reseñas">
            <div class="info-landing my-auto">
                <h1 class="text-[22px] lg:text-40 font-medium leading-8 lg:leading-snug">
                    Reduce el tiempo de la gestión de reseñas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Disfruta de una gestión centralizada, eliminando la necesidad de revisar múltiples plataformas y asegurando una respuesta oportuna a los comentarios de los huéspedes gracias a la actualización continua de la Consola de reseñas.  <br/><br/>
                    Al responder a las reseñas, no solo incrementas la posibilidad de que vuelvan a elegir tu alojamiento, sino que también proyectas una imagen positiva a otros potenciales huéspedes, demostrando un compromiso activo con la satisfacción del cliente.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/repu-1.jpg" alt="Reduce el tiempo de la gestión de reseñas">
        </section>
    
        <section id="repu-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="mr-landing info-landing lg:my-auto">
                <h1 class="text-[22px] lg:text-40 font-medium leading-8 lg:leading-snug">
                    Todas las reseñas en un mismo lugar
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Es difícil hacer seguimiento a tantas plataformas a la vez y responder a cada opinión que dejan en ellas. Nosotros aunamos todos los datos sobre las reseñas de Booking, Expedia, Google y TripAdvisor, entre los que se encuentran los datos de la persona que deja la opinión, el contenido de la propia opinión y, en caso de que se haya respondido por la propiedad, los datos de la respuesta. 
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/repu-2.jpg" alt="Todas las reseñas en un mismo lugar">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/repu-2-3x.jpg" alt="Todas las reseñas en un mismo lugar">
        </section>
    
        <section id="repu-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="mr-landing image-landing my-auto hidden lg:block hidden-xl" src="/img/repu-3.jpg" alt="Conexión automática, análisis instantáneo">
            <img class="mr-landing image-landing my-auto hidden lg:block show-xl" src="/img/repu-3.jpg" alt="Conexión automática, análisis instantáneo">
            <div class="info-landing my-auto">
                <h1 class="text-[22px] lg:text-40 font-medium leading-8 lg:leading-snug">
                    Tu reputación, a un solo clic
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con solo introducir el nombre de tu alojamiento, obtendrás acceso inmediato a todas las reseñas de Booking, Expedia, TripAdvisor y Google. Olvídate de conexiones complicadas o de introducir enlaces a tus perfiles. No notarás el proceso y tendrás a tu disposición tanto datos agregados (ratios, sumatorios, etc.) como cada una de las reseñas individuales.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/repu-3.jpg" alt="Conexión automática, análisis instantáneo">
        </section>
    
        <section id="repu-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>