<template>
    <div class="w-full bg-white lg:fixed z-50 left-0 pt-8 pb-4">
        <div id="head-menu" class="flex">
            <div class="ttle-sm h-[66px] hborder-bottom-gray-400 py-6 mr-[40px]">
                <p class="text-base font-medium leading-4">
                    Plataformas
                </p>
            </div>
            <div class="ttle-g h-[66px] hborder-bottom-gray-400 py-6 mr-[40px]">
                <p class="text-base font-medium leading-4">
                    Propuestas de valor
                </p>
            </div>
            <div class="ttle-sm h-[66px] hborder-bottom-gray-400 py-6">
                <p class="text-base font-medium leading-4">
                    Te llamamos
                </p>
            </div>
        </div>
        <div id="body-menu" class="flex">
            <div class="box-sm hover-boxlink flex-shrink-0 mr-6">
                <router-link :to="{name:'hoster'}" class="flex px-2 py-4 rounded-10 w-link mt-4">
                    <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.SaasHoster.svg" alt="Saas Hoster">
                    <div>
                        <h4 class="text-sm font-semibold">SaaS</h4>
                        <p class="text-sm mt-2">Plataforma integral para tu hotel</p>
                    </div>
                </router-link>
                <router-link :to="{name:'guest'}" class="flex px-2 py-4 rounded-10 w-link mt-4">
                    <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.WebappHuespedes.svg" alt="Webaapp Huésped">
                    <div>
                        <h4 class="text-sm font-semibold">WebApp</h4>
                        <p class="text-sm mt-2">Mejora la experiencia de tus huéspedes</p>
                    </div>
                </router-link>
            </div>
            <div class="box-g hover-boxlink flex-shrink-0 mr-8">
                <div class="flex justify-between mt-4">
                    <router-link :to="{name:'comunication'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.Comunicación.svg" alt="Comunicación">
                        <div>
                            <h4 class="text-sm font-semibold">Comunicación</h4>
                            <p class="text-sm mt-2">Conecta y comunícate con tus huéspedes</p>
                        </div>
                    </router-link>
                    <router-link :to="{name:'reputation'}" class="flex px-2 py-4 rounded-10 w-link">
                        <img class="mr-4 h-8 w-8 flex-shrink-0 mb-auto" src="/img/icons/1.TH.Icon.Header.ReputacionOnline.svg" alt="Reputación online">
                        <div>
                            <h4 class="text-sm font-semibold">Reputación online</h4>
                            <p class="text-sm mt-2">Lidera las OTAs</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="box-md hover-boxlink flex-shrink-0">
                <p class="mt-8 text-sm font-medium">Completa la experiencia, mejora las reseñas, aumenta tus ingresos</p>
                <p class="text-sm mt-2">Nos ponemos en contacto contigo</p>
                <div class="mt-8">
                    <button @click="contact_us" class="hbtn-cta text-sm py-3 rounded-6 text-center w-full">
                        Te llamamos
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
function contact_us () {
    router.push({ name: 'contact' });
}
</script>
<style lang="scss" scoped> 
a:hover h4{
    color: #34A98F;
}
.hover-boxlink a:hover {
    background-color: #ECF9F5;
}

.w-link{
    width: 252px;
}
#head-menu{
    padding: 0 112px;
    .ttle-sm{
        width: 236px;
    }
    .ttle-g{
        width: 504px;
    }
}

#body-menu{
    padding: 0 104px;
    .box-sm{
        width: 252px;
    }
    .box-md{
        width: 236px;
    }
    .box-g{
        width: 520px;
    }
}
@media (min-width:1440px) {
    .w-link{
        width: 288px;
    }
    #head-menu{
        .ttle-sm{
            width: 272px;
        }
        .ttle-g{
            width: 592px;
        }
    }   
    #body-menu{
    padding: 0 104px;
    .box-sm{
        width: 288px;
    }
    .box-md{
        width: 272px;
    }
    .box-g{
        width: 608px;
    }
}
}
@media (min-width:1920px) {
    #head-menu{
        padding: 0 352px;    
    }
    #body-menu{
        padding: 0 344px;       
    }
}

</style>