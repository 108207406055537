<template>
    <Layout>
        <section id="corta-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Reduce las reseñas negativas
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Una mala reseña puede suponer la pérdida de hasta 30 nuevas reservas. Identifica y satisface a los huéspedes descontentos antes de que afecten a tu reputación online.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/corta-banner.jpg" alt="Reduce las reseñas negativas">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/corta-banner-3x.jpg" alt="Reduce las reseñas negativas">
        </section>
    
        <section id="corta-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Identifica huéspedes menos satisfechos" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Identifica huéspedes menos satisfechos
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Recibe menos reseñas negativas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Recibe menos reseñas negativas
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Protege y mejora tu reputación online" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Protege y mejora tu reputación online
                </p>
            </div>
        </section>
    
        <section id="corta-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/corta-1.jpg" alt="Tu escudo contra las reseñas negativas">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/corta-1-3x.jpg" alt="Tu escudo contra las reseñas negativas">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Tu escudo contra las reseñas negativas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Al igual que un cortafuegos previene la propagación de un incendio, nuestra herramienta detecta y atiende los descontentos a tiempo, evitando que se conviertan en reseñas negativas que afecten tu reputación online. Protege tu imagen y asegura una experiencia positiva para tus huéspedes.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/corta-1.jpg" alt="Mejores reseñas, mayor credibilidad">
        </section>
    
        <section id="corta-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Resuelve problemas en tiempo real
                </h1>
                <!-- <p class="text-sm lg:text-base font-medium mt-8">
                </p> -->
                <ul class="list-disc mt-6 pl-4">
                    <li class="text-sm lg:text-base font-medium leading-6 lg:leading-7">Chat directo: Establece una línea directa de comunicación con tus huéspedes para identificar y resolver problemas antes de que escalen.</li>
                    <li class="text-sm lg:text-base font-medium leading-6 lg:leading-7">Respuestas rápidas: Implementa un sistema de respuestas automáticas para mantener a los huéspedes informados, incluso en momentos de alta demanda.</li>
                    <li class="text-sm lg:text-base font-medium leading-6 lg:leading-7">Seguimiento proactivo: Realiza un seguimiento proactivo de las preocupaciones de los huéspedes, evitando así reseñas negativas post-estancia.</li>
                </ul>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/corta-2.jpg" alt="Resuelve problemas en tiempo real">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/corta-2-3x.jpg" alt="Resuelve problemas en tiempo real">
        </section>
    
        <section id="corta-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/corta-3.jpg" alt="Relájate y observa">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/corta-3-3x.jpg" alt="Relájate y observa">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Implantación sencilla, protección duradera
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Configura el chat en sencillos pasos y empieza a disfrutar de una herramienta poderosa para proteger tu reputación online. Con una respuesta proactiva y atención personalizada, convierte experiencias negativas en positivas, fomentando reseñas favorables y construyendo una base sólida de huéspedes satisfechos.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/corta-3.jpg" alt="Relájate y observa">
        </section>
    
        <section id="repu-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
    