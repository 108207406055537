<template>
    <Layout>
        <section id="hoster-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] lg:mt-4 lg:w-auto">
                    Plataforma integral para tu hotel
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Mejora la experiencia de tus huéspedes y tu reputación online en las principales OTAs en una única plataforma. 
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/hoster-banner.jpg" alt="Plataforma integral para tu hotel">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/hoster-banner-3x.jpg" alt="Plataforma integral para tu hotel">
        </section>
    
        <section id="hoster-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Plataforma SaaS y WebApp personalizadas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Plataforma SaaS y WebApp personalizadas
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Mejora la experiencia sin aumentar el trabajo" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Mejora la experiencia sin aumentar el trabajo
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Mejora la reputación online y tus ingresos" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Mejora la reputación online y tus ingresos
                </p>
            </div>
        </section>
    
        <section id="hoster-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/hoster-1.jpg" alt="Plataformas “llave en mano”">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/hoster-1-3x.jpg" alt="Plataformas “llave en mano”">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Plataformas “llave en mano”
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Tanto la WebApp que disfrutarán tus huéspedes como la plataforma para gestionar las interacciones con ellos estarán listas en menos de un día. No tendrás que esperar para utilizarlas y aprovechar sus beneficios.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/hoster-1.jpg" alt="Plataformas “llave en mano”">
        </section>
    
        <section id="hoster-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Gestión a tu medida
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Aunque ofrecemos un servicio llave en mano, tendrás la libertad de personalizar la WebApp a tu gusto, garantizando que se alinee con la identidad y necesidades de tu alojamiento.
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/hoster-2.jpg" alt="Gestión a tu medida">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/hoster-2-3x.jpg" alt="Gestión a tu medida">
        </section>
    
        <section id="hoster-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/hoster-3.jpg" alt="Operación fluida y monitoreo de reseñas">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/hoster-3-3x.jpg" alt="Operación fluida y monitoreo de reseñas">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Operación fluida y monitoreo de reseñas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Invita a tus huéspedes a la WebApp con un proceso de creación de estancias sencillo y eficiente. Además, tendrás a tu disposición la Consola de reseñas, donde se centralizan todas las valoraciones de las principales OTAs.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/hoster-3.jpg" alt="Operación fluida y monitoreo de reseñas">
        </section>

        <section id="hoster-section-4" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Análisis para una estrategia efectiva
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Utiliza métricas y análisis de reseñas para entender las fortalezas y debilidades de tu alojamiento. Convierte estos datos en estrategias efectivas para la mejora continua de tu servicio y reputación online.
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/hoster-4.jpg" alt="Análisis para una estrategia efectiva">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/hoster-4-3x.jpg" alt="Análisis para una estrategia efectiva">
        </section>

        <section id="hoster-section-5" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/hoster-5.jpg" alt="Mejora la comunicación con tus huéspedes">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/hoster-5-3x.jpg" alt="Mejora la comunicación con tus huéspedes">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Mejora la comunicación con tus huéspedes
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con nuestra Solución de Comunicación, te ofrecemos una gama completa de herramientas diseñadas para mejorar la interacción con tus huéspedes. Desde un directorio detallado de tu alojamiento hasta un chat en tiempo real, cubrimos todas las bases para garantizar una comunicación fluida y efectiva que elevará la experiencia del huésped.
                </p>
                <router-link :to="{name:'comunication'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                    Más sobre la Solución de Comunicación
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre la Solución de Comunicación">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/hoster-5.jpg" alt="Mejora la comunicación con tus huéspedes">
        </section>

        <section id="hoster-section-6" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Lidera las OTAs y maximiza tus ingresos
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    La reputación online es más crucial que nunca en la industria de la hospitalidad. Nuestra Solución de Reputación Online te proporciona un conjunto integral de herramientas que te permiten no solo monitorizar lo que se dice de tu alojamiento en las OTAs, sino también tomar medidas proactivas para mejorar tu posición y atraer más reservas.
                </p>
                <router-link :to="{name:'reputation'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                    Más sobre la Solución de Reputación Online
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre la Solución de Reputación Online">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/hoster-6.jpg" alt="Lidera las OTAs y maximiza tus ingresos">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/hoster-6-3x.jpg" alt="Lidera las OTAs y maximiza tus ingresos">
        </section>
    
        <section id="hoster-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
   