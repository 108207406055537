<template>
    <Layout>
        <section id="chat-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Comunicación directa con tus huéspedes
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    El 70% de los huéspedes prefiere comunicarse con el personal del hotel a través de canales digitales. Optimiza la experiencia de tus huéspedes a través del chat.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/chat-banner.jpg" alt="Comunicación directa con tus huéspedes">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/chat-banner-3x.jpg" alt="Comunicación directa con tus huéspedes">
        </section>
    
        <section id="chat-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Mejora la experiencia de tus huéspedes en la ciudad" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Comunícate con tus huéspedes con fluidez
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Personaliza la guía de tu hotel según tu estilo" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Resuelve problemas en tiempo real
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Ahorra tiempo y esfuerzo en investigación " style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Configura el chat según tus necesidades
                </p>
            </div>
        </section>
        
        <section id="chat-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block" src="/img/chat-1.jpg" alt="Ofrece una guía turística completa de la ciudad">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Evita insatisfacciones de tus huéspedes
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Podrás estar en contacto directo con tus huéspedes, permitiéndoles expresar sus necesidades y preguntas en tiempo real, lo que te brinda la oportunidad de ofrecer soluciones inmediatas y mejorar la calidad del servicio que ofreces. <br> <br>
                    Además, incrementarás las posibilidades de obtener críticas positivas y, por ende, atraer a más huéspedes en el futuro.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/chat-1.jpg" alt="Ofrece una guía turística completa de la ciudad">
        </section>
    
        <section id="chat-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Facilita la comunicación y evita ineficiencias
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Define los idiomas disponibles para la atención al cliente y establece horarios de atención acorde a tu disponibilidad. Lo más importante, nuestra plataforma está diseñada para establecer una comunicación fluida y eficiente con tus huéspedes gracias a:
                </p>
                <div class="lg:mt-8 grid grid-cols-1 lg:grid-cols-2">
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Idiomas
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Indica los idiomas de atención disponibles a tus huéspedes
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Horarios
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Establece el  horario de atención según tu disponibilidad
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6">
                            Notificaciones
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Ayuda a tus huéspedes a descubrir los puntos de mayor interés, incluidos monumentos, museos, parques y jardines.
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6">
                            Respuestas automáticas
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Para evitar tiempos de espera, incluso fuera del horario de atención
                        </p>
                    </div>
                </div>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto" src="/img/chat-2.jpg" alt="Organización por categorías para una navegación sencilla">
        </section>
    
        <section id="chat-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/chat-3.jpg" alt="Una guía adaptada a tu estilo">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/chat-3-3x.jpg" alt="Una guía adaptada a tu estilo">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Respuesta rápida, satisfacción garantizada
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    En la era de la inmediatez, garantiza respuestas rápidas a tus huéspedes. Nuestro sistema de notificaciones asegura que el personal esté siempre al tanto de los nuevos mensajes, permitiendo una comunicación fluida y sin demoras. <br><br>
                    Para los momentos de alta demanda o ausencia, las respuestas automáticas mantendrán a los huéspedes informados y tranquilos. Y no te preocupes por los horarios de no disponibilidad: configúralos en un minuto y evita malentendidos.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/chat-3.jpg" alt="Una guía adaptada a tu estilo">
        </section>
    
        <section id="chat-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        //imports components
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    
    </script>
    