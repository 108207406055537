<template>
  <Layout>
      <section class="max-w-[1216px]">
          <h1 class="text-[32px] lg:text-[64px] font-medium lg:text-center lg:w-[1008px] mx-auto mt-14 lg:mt-0 leading-10 lg:leading-[150%]">
              Maximiza tus ingresos mediante una gestión integral del huésped.
          </h1>
          <p class="text-base lg:text-[22px] lg:leading-8 font-medium lg:text-center lg:w-[1008px] mx-auto mt-8">
              El Guest Management System (GMS) de TheHoster está diseñado para mejorar la experiencia de los huéspedes y 
              optimizar tu reputación online. Una plataforma integral y ‘llave en mano’ que te ayudará a diferenciarte en el 
              mercado.
          </p>
          <div class="lg:text-center mt-8 lg:mt-12">
              <button @click="contact_us" class="hbtn-cta text-base font-medium text-center w-full lg:w-auto py-[19px] lg:px-[36px]">
                  Te llamamos
              </button>
              <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
          </div>
      </section>    
      
      <section class="mt-16 lg:mt-20 max-w-[1216px]">
          <img class="hidden-xl" src="/img/home-banner.jpg" alt="Maximiza tus ingresos mediante una gestión integral del huésped">
          <img class="show-xl" src="/img/home-banner-3x.jpg" alt="Maximiza tus ingresos mediante una gestión integral del huésped">
      </section>
      <section id="home-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
          <div class="w-full lg:w-1/3 flex">
              <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Información completa" style="width: 4px;height: 104px;">
              <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                  Información completa
              </p>
          </div>
          <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
              <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Comunicación directa" style="width: 4px;height: 104px;">
              <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                  Comunicación directa
              </p>
          </div>
          <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
              <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Fomenta reseñas positivas" style="width: 4px;height: 104px;">
              <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                  Fomenta reseñas positivas
              </p>
          </div>
      </section>
  
      <section id="home-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
          <div class="info-landing mr-landing lg:my-auto">
              <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                  Desafío de comunicación
              </h1>
              <p class="text-sm lg:text-base font-medium mt-8">
                  La comunicación ineficaz y la información desactualizada pueden resultar en una experiencia insatisfactoria para el huésped. La falta de claridad o respuestas tardías a las inquietudes de los huéspedes pueden generar frustración y afectar negativamente la percepción del huésped sobre el alojamiento. <br><br>
                  En una industria donde la satisfacción del cliente es clave, los desafíos de comunicación pueden tener un impacto directo en la reputación y el éxito del alojamiento.
              </p>
          </div>
          <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/home-1.jpg" alt="Desafío de comunicación">
          <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/home-1-3x.jpg" alt="Desafío de comunicación">
      </section>
  
      <section id="home-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
          <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/home-2.jpg" alt="Retos sobre reputación online">
          <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/home-2-3x.jpg" alt="Retos sobre reputación online">
          <div class="info-landing my-auto">
              <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                  Retos sobre reputación online
              </h1>
              <p class="text-sm lg:text-base font-medium mt-8">
                  La reputación online de un alojamiento es vital para atraer y retener clientes. Las reseñas negativas, las respuestas tardías a las valoraciones, y la necesidad de gestionar reseñas en múltiples OTAs pueden poner en riesgo la imagen del alojamiento y disuadir a potenciales clientes.  <br> <br>
                  La gestión proactiva de la reputación online es crucial para mantener una imagen positiva, fomentar la confianza de los clientes y, en última instancia, incrementar las reservas y los ingresos.
              </p>
          </div>
          <img class="image-landing mt-8 lg:hidden" src="/img/home-2.jpg" alt="Retos sobre reputación online">
      </section>

      <section id="home-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
          <div class="info-landing mr-landing lg:my-auto">
              <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                  Solución de Comunicación
              </h1>
              <p class="text-sm lg:text-base font-medium mt-8">
                  TheHoster personaliza la WebApp de tu hotel que centraliza la información relevante sobre el alojamiento, la ciudad y las actividades turísticas a disposición de tus huéspedes.  <br><br>
                  Esta plataforma integral no solo ofrece información actualizada, sino que también incluye un canal directo de comunicación entre el hotel y los huéspedes. <br><br>
                  Este flujo de comunicación mejora significativamente la experiencia del huésped, reflejándose positivamente en su satisfacción.<br><br>
              </p>
              <router-link :to="{name:'comunication'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                  Más sobre Solución de Comunicación
                  <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Solución de Comunicación">
              </router-link>
          </div>
          <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/home-3.jpg" alt="Solución de Comunicación">
          <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/home-3-3x.jpg" alt="Solución de Comunicación">
      </section>

      <section id="home-4" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
          <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/home-4.jpg" alt="Mejora la comunicación con tus huéspedes">
          <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/home-4-3x.jpg" alt="Mejora la comunicación con tus huéspedes">
          <div class="info-landing my-auto">
              <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                  Solución de Reputación online
              </h1>
              <p class="text-sm lg:text-base font-medium mt-8">
                  TheHoster facilita una gestión proactiva de valoraciones, incentivando a huéspedes satisfechos a compartir sus experiencias positivas en las principales OTAs y proporcionando herramientas analíticas para monitorizar reseñas en una plataforma unificada.  <br><br>
                  Esta gestión estratégica no solo mejora la reputación online del alojamiento, sino que simplifica la gestión y, también, proporciona insights valiosos para la mejora continua.
              </p>
              <router-link :to="{name:'reputation'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                  Más sobre Solución de Reputación Online
                  <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Solución de Reputación Online">
              </router-link>
          </div>
          <img class="image-landing mt-8 lg:hidden" src="/img/home-4.jpg" alt="Mejora la comunicación con tus huéspedes">
      </section>

      <section id="home-5" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
          <div class="info-landing mr-landing lg:my-auto">
              <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                  Optimiza tu gestión
              </h1>
              <p class="text-sm lg:text-base font-medium mt-8">
                  Descubre cómo TheHoster empodera a los alojamientos a través de una plataforma SaaS diseñada específicamente para optimizar la gestión del huésped y de la reputación online.  <br><br>
                  Con una configuración "plug & play" y una interfaz intuitiva, TheHoster se integra sin problemas en tu operativa diaria, permitiéndote centrarte en lo que mejor sabes hacer, ofrecer una experiencia inolvidable a tus huéspedes.
              </p>
              <router-link :to="{name:'hoster'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                  Más sobre SaaS - Hosters
                  <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre SaaS - Hosters">
              </router-link>
          </div>
          <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/home-5.jpg" alt="Optimiza tu gestión">
          <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/home-5-3x.jpg" alt="Optimiza tu gestión">
      </section>

      <section id="home-6" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
          <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/home-6.jpg" alt="Enriquece la estancia de tus huéspedes">
          <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/home-6-3x.jpg" alt="Enriquece la estancia de tus huéspedes">
          <div class="info-landing my-auto">
              <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                  Enriquece la estancia de tus huéspedes
              </h1>
              <p class="text-sm lg:text-base font-medium mt-8">
                  Descubre cómo TheHoster transforma la estancia de tus huéspedes a través de una WebApp personalizada. Esta herramienta no solo centraliza la información relevante para tus clientes, sino que también facilita una comunicación directa y efectiva con ellos. <br><br>
                  Con TheHoster, tienes la oportunidad de elevar la satisfacción del cliente, mejorar la comunicación y, por ende, potenciar tu reputación online, asegurando que cada huésped disfrute de una experiencia excepcional en tu alojamiento.
              </p>
              <router-link :to="{name:'guest'}" class="mt-8 text-sm lg:text-base font-medium ghost-green-600 flex">
                  Más sobre WebApp - Huéspedes
                  <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre WebApp - Huéspedes">
              </router-link>
          </div>
          <img class="image-landing mt-8 lg:hidden" src="/img/home-6.jpg" alt="Enriquece la estancia de tus huéspedes">
      </section>
  
      <section id="hoster-experience" class="mt-16 lg:mt-40 lg:flex ">
          <ExperienceInfo />
      </section>
  </Layout>
  </template>
  
  <script setup>
      //imports components
      import { useRouter  } from 'vue-router';
      import Layout from '@/layouts/MainLayout.vue'
      import ExperienceInfo from './landings/components/ExperienceInfo.vue'
      const router = useRouter();
      function contact_us () {
        router.push({name:'contact'});
      }
      
  </script>
 