<template>
  <!--header-->
  <header class="border-b border-gray-300">
      <div class="py-4 px-4 lg:px-6 container-fluid-landing">
          <img 
              @click="go_home"
              src="/img/1.TH.logo.svg" 
              class="w-36 lg:w-56 h-8 lg:h-10 inline cursor-pointer"
          >
      </div>
  </header>
   <!-- end header-->

  <main class="lg:mt-10 lg:flex lg:flex-row lg:justify-between relative">
      <div>
          <section class="width-text">
              <h1 class="text-3xl lg:text-64 font-medium leading-snug lg:leading-snug pr-16 lg:pr-0">
                  Envíanos un mensaje
              </h1>
              <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                  Si prefieres que te contactemos, completa el siguiente formulario con tus datos y nos pondremos en contacto contigo lo antes posible.
              </p>
          </section>
          <section class="contact-form mt-12 lg:hidden">
              <ContactForm :errorsKey="errorsKey"/>
          </section>
          <section class="width-text mt-8 lg:mt-20">
              <div class="">
                  <h1 class="text-3xl lg:text-64 font-medium leading-snug lg:leading-snug pr-16 lg:pr-0">
                      Contacta con nosotros
                  </h1>
                  <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                      Estamos aquí para ayudarte y responder cualquier pregunta que puedas tener sobre TheHoster. No dudes en ponerte en contacto con nosotros a través de cualquiera de los siguientes métodos:         
                  </p>
              </div>
              <div class="mt-8 lg:mt-12">
                  <p class="text-base lg:text-[22px] font-medium">
                      <img class="inline mr-4" src="/img/icons/card-message-outline.svg">
                      info@thehoster.io
                  </p>
                  <p class="text-base lg:text-[22px] font-medium mt-3.5">
                      <img class="inline mr-4" src="/img/icons/phone-outline.svg">
                      +34 624 149 605
                  </p>
              </div>
              <div class="my-8 lg:my-20">
                  <h1 class="tx-22 lg:text-40 font-medium leading-snug lg:leading-snug">
                      Completa la experiencia, 
                      mejora las reseñas, 
                      aumenta tus ingresos
                  </h1>
                  <p class="text-sm lg:text-[22px] font-medium mt-8 lg:leading-8">
                      Aumenta tu precio en un 11.2%, la ocupación en un 14.2% y 
                      el RevPAR en un 16.3% con una mejora de un
                      punto en las valoraciones en línea
                  </p>
              </div>
          </section>
      </div>
      <!-- FORM -->
      <section class="contact-form hidden lg:block">
          <ContactForm :errorsKey="errorsKey"/>
      </section>
      <!-- END FORM -->


  </main>
 
</template>
<script setup>
  // import { ref } from 'vue';
  import { useRouter  } from 'vue-router';
  import ContactForm from './ContactForm.vue'
  
  
  // const toast = inject('toast');
  const router = useRouter();
  //functions
  function go_home () {
      router.push({name:'homePage'});
      
  }
  
</script>
<style scoped>
span{
  color:#FFD453;
}
main{
  padding: 40px 112px;
  max-width: 1280px;
}
@media (max-width:1020px) {
  main{
          padding: 24px 16px;
  }
  .tx-22{
      font-size:22px;
  }
}
@media (min-width:1020px) {
  .width-text{
      width: 512px;
  }
}
@media (min-width:1440px) {
  main{
      padding: 40px 0;
      margin-left: auto !important;
      margin-right: auto !important;
  }
  .width-text{
      width: 592px;
  }
}
</style>