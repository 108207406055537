<template>
    <Layout>
        <section id="ie-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium lg:leading-snug w-[328px] lg:w-auto">
                    Experiencias únicas en la ciudad
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Facilita a tus huéspedes el acceso a más de 6.000 experiencias únicas y enriquece su estancia en tu alojamiento.
                </p>
                <button @click="contact_us" class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto" src="/img/exp-banner-new.jpg" alt="Experiencias únicas en la ciudad">
        </section>
    
        <section id="ie-interest" class="flex flex-col lg:flex-row items-center mt-16 lg:mt-20 interest">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Opciones para todos los gustos y todos los turistas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 lg:pr-8 my-auto">
                    Opciones para todos los gustos
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Fideliza a tus clientes con un servicio de calidad" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 lg:pr-8 my-auto">
                    Disponibilidad en tiempo real
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Recomienda experiencias y obtén un ingreso adicional" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Completa la experiencia de tus huéspedes
                </p>
            </div>
        </section>
    
        <section id="ie-catalogue" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing my-auto hidden lg:inline mr-landing" src="/img/exp-1-new.jpg" alt="Un gran catálogo de experiencias para tus huéspedes">
            <div class="info-landing  my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Enriquece la estancia de tus huéspedes
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Ofrece una amplia gama de experiencias locales directamente desde una única plataforma, evitando el esfuerzo de negociar con múltiples proveedores. <br><br>
                    Además, al personalizar las recomendaciones, aseguras que cada huésped encuentre actividades que resuene con sus intereses, aumentando su satisfacción y enriqueciendo su estancia.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/exp-1-new.jpg" alt="Un gran catálogo de experiencias para tus huéspedes">
        </section>
    
        <section id="ie-tastes" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing my-auto mr-landing">
                <h1 class="tx22 lg:text-40 font-medium lg:leading-snug">
                    Un mundo de experiencias a tu alcance
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Desde la comodidad de la WebApp, tus huéspedes tendrán acceso a una amplia selección de más de 6.000 actividades turísticas, con las que disfrutar al máximo su estancia. La oferta es diversa, desde sencillos tours a pie hasta emocionantes vuelos en helicóptero y exclusivos cruceros. <br><br>
                    Garantiza que cada huésped encuentre su actividad perfecta y convierte su visita en una experiencia inolvidable.
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto lg:mb-auto hidden-xl" src="/img/exp-2-new.jpg" alt="Comodidad para tus huéspedes y para ti">
            <img class="image-landing mt-8 lg:mt-auto lg:mb-auto show-xl" src="/img/exp-2-3xOPT.jpg" alt="Comodidad para tus huéspedes y para ti">
        </section>
    
        <section id="ie-comfort" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing my-auto hidden lg:inline mr-landing" src="/img/exp-3-new.jpg" alt="Comodidad para tus huéspedes y para ti">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium lg:leading-snug">
                    Tu catálogo 'llave en mano' de experiencias
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Desde el primer momento, ponemos a tu disposición toda la gama experiencias turísticas, bien categorizadas y listas para ser exploradas por tus huéspedes. <br> <br>
    Personaliza recomendaciones y destaca aquellas actividades que mejor se alineen con el estilo de tu hotel, ofreciendo así un servicio único y diferenciado, sin las complicaciones de gestionar reservas o negociar con proveedores.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/exp-3-new.jpg" alt="Comodidad para tus huéspedes y para ti">
        </section>
        <section id="ie-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
    
    <style scoped>
    
    </style>
    