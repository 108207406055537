<template>
    <Layout>
        <section id="ana-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Analiza tu reputación online
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Descubre el estado de tu reputación online a través de métricas detalladas y establece estrategias basadas en datos sólidos para alcanzar tus objetivos.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/ana-banner.jpg" alt="Analiza tu reputación online">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/ana-banner-3x.jpg" alt="Analiza tu reputación online">
        </section>
    
        <section id="ana-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Reseñas que guían tu estrategia" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Reseñas que guían tu estrategia
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Decisiones basadas en datos" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Decisiones basadas en datos
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Descubre tendencias de tus reseñas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Descubre tendencias de tus reseñas
                </p>
            </div>
        </section>
    
        <section id="ana-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/ana-1.jpg" alt="Identifica fortalezas y áreas de mejora">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/ana-1-3x.jpg" alt="Identifica fortalezas y áreas de mejora">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Identifica fortalezas y áreas de mejora
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Identifica claramente tus puntos fuertes y las áreas que requieren atención a través del análisis centralizado de tus valoraciones. Obten conclusiones claras y accionables que servirán como brújula para definir estrategias efectivas para tu alojamiento, optimizando continuamente tu servicio y reputación.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/ana-1.jpg" alt="Identifica fortalezas y áreas de mejora">
        </section>
    
        <section id="ana-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Análisis centralizado e intuitivo
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Centralizamos la información de distintas OTAs, ofreciendo una visualización gráfica que refleja de manera precisa y representativa tu reputación online. Es más que un conjunto de datos; es la imagen clara y contundente de tu situación actual en el mercado. <br> <br>
                    Una imagen vale más que mil palabras; con nuestro análisis, esa imagen está al alcance de tu mano, lista para ayudarte a tomar decisiones informadas. Algo así es imposible de explicar, ¡hay que verlo y usarlo!
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/ana-2.jpg" alt="Resuelve problemas en tiempo real">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/ana-2-3x.jpg" alt="Resuelve problemas en tiempo real">
        </section>
    
        <section id="ana-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/ana-3.jpg" alt="Conexión automática, análisis instantáneo">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/ana-3-3x.jpg" alt="Conexión automática, análisis instantáneo">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Conexión automática, análisis instantáneo
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Bajo nuestro enfoque “llave en mano”, solo necesitamos el nombre del alojamiento. Inmediatamente, accederás a un dashboard completo para analizar la situación actual y pasada de tu hotel en las OTAs más relevantes.  <br> <br>
                    Olvídate de configuraciones complejas; nuestro sistema se encarga de recopilar y presentar los datos de manera intuitiva, facilitando la toma de decisiones basadas en información concreta.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/ana-3.jpg" alt="Conexión automática, análisis instantáneo">
        </section>
    
        <section id="ana-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
   