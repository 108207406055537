<template>
    <Layout>
        <section id="reput-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Lidera las OTAs
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    El 79% de los viajeros elige el alojamiento con la calificación más alta. Asegura tu liderazgo en las OTAs.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto" src="/img/reput-banner-new.jpg" alt="Lidera las OTAs">
        </section>
    
        <section id="reput-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Centraliza y monitoriza tus reseñas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Centraliza y monitoriza tus reseñas
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Impulsa las reseñas positivas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Impulsa las reseñas positivas
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Optimiza tu estrategia con análisis continuo" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Optimiza tu estrategia con análisis continuo
                </p>
            </div>
        </section>
        
        <section id="reput-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/reput-1-new.jpg" alt="Reputación destacada, mayores ingresos">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/reput-1-3x.jpg" alt="Reputación destacada, mayores ingresos">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Reputación destacada, mayores ingresos
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Tu reputación online influye directamente en la elección de alojamiento por parte de los viajeros. Con nuestra solución, obtendrás una visión clara de las opiniones de tus huéspedes y podrás actuar para mejorarlas. Aumenta tus reservas, fideliza a tus clientes y maximiza tus ingresos.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/reput-1-new.jpg" alt="Reputación destacada, mayores ingresos">
        </section>
    
        <section id="reput-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Captación estratégica de reseñas positivas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Aumenta la cantidad y calidad de las reseñas en las principales OTAs con nuestra herramienta de Captación de reseñas. Identificamos a los huéspedes más satisfechos y los alentamos a compartir sus experiencias positivas, mejorando así tu posicionamiento y reputación online.
                </p>
                <router-link :to="{name:'capturing_reviews'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Captación de reseñas
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Captación estratégica de reseñas positivas">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto" src="/img/reput-2-new.jpg" alt="Captación estratégica de reseñas positivas">
        </section>
    
        <section id="reput-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block" src="/img/reput-3-new.jpg" alt="Todas tus reseñas, en un solo lugar">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Todas tus reseñas, en un solo lugar
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Administrar reseñas dispersas en múltiples OTAs puede ser una labor tediosa. Nuestra Consola de reseñas agrega todas las valoraciones en un solo dashboard. Esto te permite detectar áreas de mejora, reconocer patrones y actuar proactivamente para mejorar la experiencia del huésped y tu reputación online.
                </p>
                <router-link :to="{name:'console_reviews'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Consola de reseñas
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Consola de reseñas">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/reput-3-new.jpg" alt="Todas tus reseñas, en un solo lugar">
        </section>

        <section id="reput-section-4" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Minimiza las reseñas negativas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Las malas reseñas pueden tener un impacto duradero en tu reputación online. Nuestro producto 'Cortafuegos' te brinda un chat directo con tus huéspedes y un sistema de notificaciones, permitiéndote resolver problemas en tiempo real antes de que se conviertan en reseñas negativas. Actúa proactivamente y protege tu reputación.
                </p>
                <router-link :to="{name:'firewall'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Cortafuegos
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Más sobre Cortafuegos">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto" src="/img/reput-4-new.jpg" alt="Minimiza las reseñas negativas">
        </section>
        
        <section id="reput-section-5" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block" src="/img/reput-5-new.jpg" alt="Convierte reseñas en estrategias">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Convierte reseñas en estrategias
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    En un mar de opiniones dispersas en diferentes OTAs, es fácil perderse y no identificar tendencias, fortalezas y áreas de mejora. Nuestro producto Análisis centraliza la información, ofreciendo una visualización gráfica que refleja tu reputación online. Utiliza esta información para tomar decisiones informadas y estratégicas.
                </p>
                <router-link :to="{name:'analysis'}" class="mt-8 text-sm lg:text-base font-medium block ghost-green-600">
                    Más sobre Análisis
                    <img class="inline ml-2 w-6 h-6  ghost-green-600-icon" src="/img/icons/arrow_right.svg" alt="Convierte reseñas en estrategias">
                </router-link>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/reput-5-new.jpg" alt="Convierte reseñas en estrategias">
        </section>

        <section id="reput-section-6" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Llave en mano para un impacto significativo
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Nuestra solución es verdaderamente plug and play. Una vez que te registras con nosotros, nos encargamos de todo: captar nuevas reseñas positivas, ofrecerte un chat para la resolución de problemas, un panel centralizado con todas las reseñas y un dashboard para analizar todos los datos.
                </p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto" src="/img/reput-6-new.jpg" alt="Llave en mano para un impacto significativo">
        </section>
    
        <section id="reput-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>
    