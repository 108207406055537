<template>
  <div id="icheckbox" :class="classes ? classes : 'w-[18px] h-[18px] border rounded-6 my-auto flex-shrink-0 cursor-pointer'" @click="toggleCheckbox">
    <div v-if="modelValue" class="checkbox-icon text-xs text-center hbg-green-600 rounded-6 text-white w-full h-full">✔</div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: Boolean,
  classes: String
});
const emit = defineEmits(['update:modelValue']);

const toggleCheckbox = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>

<style scoped>
#icheckbox:hover{
  border: 1px solid var(--h-green-600);
  background-color: var(--h-green-100);
}
</style>
