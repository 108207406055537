<template>
    <div id="app">
      <router-view />
    </div>
  </template>
  
  <script setup>
  import { defineComponent } from 'vue';
  
  defineComponent({ name: 'App' });
  </script>
  
  
  