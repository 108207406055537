<template>
	<div class="info-header-container fixed top-0 left-0 lg:sticky bg-white w-full">
		<div class="flex justify-between py-4 hborder-bottom-gray-400 px-4 lg:px-6">
            <!-- section links -->
            <div class="flex items-center">
                <img 
                    @click="go_home"
                    src="/img/1.TH.logo.svg" 
                    class="w-[150px] lg:w-56 h-8 lg:h-10 cursor-pointer"
                    alt="TheHoster"
                >
                <div 
                    class="ml-8 hidden lg:inline-block mr-1"
                >
                    <a 
                        href="javascript:void(0)" 
                        class="h-link text-base font-semibold text-black-100 pb-2"
                        :class="{'border-b-2 border-dark':visit_product && !solution_dropdown || product_dropdown}"
                        @click="open_products_menu"
                    >
                        Productos
                        <img 
                            :src="product_dropdown ? url_icons+'line_dropdown_up.svg' : url_icons+'line_dropdown.svg'" 
                            class="inline w-4"
                        >
                    </a>
                </div>
                <div 
                    class="ml-8 hidden lg:inline-block mr-1"
                    @click="open_solutions_menu"
                >
                    <a 
                        href="javascript:void(0)" 
                        class="h-link text-base font-semibold text-black-100 pb-2"
                        :class="{'border-b-2 border-dark':solution_product && !product_dropdown || solution_dropdown}"
                    >
                    Soluciones
                        <img 
                            :src="solution_dropdown ? url_icons+'line_dropdown_up.svg' : url_icons+'line_dropdown.svg'" 
                            class="inline w-4"
                        >
                    </a>
                </div>
            </div>
            <!-- END section links -->
            <!-- section sign in -->
            <div>
                <button @click="go_login" class="hbtn-primary rounded-lg px-4 py-3 text-sm font-medium mr-6 hidden lg:inline leading-[110%]">
                    Entrar
                </button>
                <button @click="contact_us" class="hbtn-cta rounded-lg px-[8px] lg:px-[16px] py-[8px] lg:py-[12px] text-sm font-medium leading-[110%]">
                    Contáctanos
                </button>
                <img 
                    @click="navmobile = true"
                    :src="url_icons+'hamburguer.svg'" 
                    class="w-6 inline lg:hidden ml-6"
                    style="margin-top: -2px;"
                >
            </div>
            <!-- END section sign in -->
        </div>
        <Transition>
            <ProductMenu v-if="product_dropdown" @click="closeMenus"/>
        </Transition>
        <Transition>
            <SolutionMenu v-if="solution_dropdown" @click="closeMenus"/>
        </Transition>
        <div 
            v-if="product_dropdown || solution_dropdown" 
            class="overlay-dropdown fixed z-10 h-screen w-full" style="background: rgba(0, 0, 0, 0.5);"
            @click="closeMenus"
        >
        </div>
	</div>
    <NavMobile :open="navmobile" @close="navmobile = false"/>
</template>

<script setup>

import { onMounted, ref, computed  } from 'vue';
import NavMobile from './NavMobile.vue'
import ProductMenu from './ProductMenu.vue'
import SolutionMenu from './SolutionMenu.vue'
import { useRouter, useRoute } from 'vue-router';

    const router = useRouter();
    const route = useRoute();
    //DATA
    const product_dropdown = ref(false)
    const solution_dropdown = ref(false)
    const navmobile = ref(false)
    const route_user = ref(null);
    const url_icons = '/img/icons/';
     
    //computed
    const visit_product = computed(() => {
        const routeName = route.name;
        if (!routeName) return false;
        return routeName === 'directory' || routeName === 'destiny' ||
            routeName === 'capturing_reviews' || routeName === 'console_reviews' ||
            routeName === 'firewall' || routeName === 'analysis' ||
            routeName === 'experiences' || routeName === 'chat';
    });

    const solution_product = computed(() => {
        const routeName = route.name;
        if (!routeName) return false;
        return routeName === 'hoster' || routeName === 'guest' ||
            routeName === 'comunication' || routeName === 'reputation';
    });
    
    onMounted(()=>{
        route_user.value = process.env.VUE_APP_ROUTE_USER_HOSTER
    })
    //FUNCTIONS

    function go_login () {
        window.open(route_user.value+'login');
    }

    function go_home () {
        router.push({ name: 'homePage' });
    }

    function contact_us () {
        router.push({ name: 'contact' });
    }

    function open_products_menu () {
        product_dropdown.value = !product_dropdown.value
        solution_dropdown.value =false
    }

    function open_solutions_menu () {
        product_dropdown.value =false
        solution_dropdown.value = !solution_dropdown.value
    }

    function closeMenus () {
        product_dropdown.value =false
        solution_dropdown.value =false
    }
   
</script>

<style scoped> 

a:hover {
    color: #000 !important;
    text-decoration: none !important;
}
a:hover h4{
    color: #34A98F;
}
.hbtn-cta{
    border: 1px solid var(--h-yellow);
}
.text-black-100{
    color:#333 !important;
}
.h-link:hover{
    padding-bottom: 8px;
    border-bottom: 2px solid #000;
}
.border-dark{
    border-color: #333;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>