import SliderPage from '@/views/slider/SliderPage.vue';

const adminRoutes = [

  {
    path: '/presentacion',
    name: 'slider',
    component: SliderPage
  },
];

export default adminRoutes;
