import { createApp } from 'vue'
import './assets/styles/tailwind.css';
import './assets/styles/custom.css';
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia';
import Toaster from '@meforma/vue-toaster';

function initializeApp () {

    const app = createApp(App);

    app.use(router);
    app.use(Toaster);
    app.use(createPinia())

    app.provide('toast', app.config.globalProperties.$toast);
    app.mount('#app');

}

initializeApp()