import { defineStore } from 'pinia';

export const useCalculatorStore = defineStore('calculator', {
  state: () => ({
    rooms: 0,
    averagePrice: 0,
    occupancyRate: 0,
    reputationIncrease: 0,
    pricePerNightIncrease: 0,
    occupancyRateIncrease: 0,
    pricePerRoomPerMonth: 8.99,
    implementationPrice: 900,
    investmentInHoster: 0,
    benefit: 0,
  }),
  actions: {
    updateCalculatorData(data) {
      Object.assign(this, data);
    },
  },
});
