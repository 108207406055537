<template>
    <Layout>
        <section id="ih-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium lg:leading-snug w-[328px] mt-4 lg:w-auto">
                    Todo sobre tu hotel, en una sola plataforma
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    Reduce hasta en un 40% las consultas recurrentes a la recepción ofreciendo información detallada y siempre actualizada a tus huéspedes.
                </p>
                <button @click="contact_us" class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-16 lg:mt-auto mb-auto" src="/img/directorio-banner.jpg" alt="Acceso completo a la información de tu hotel">
        </section>
    
        <section id="ih-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Apuesta por la digitalización y la mejora del sector" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 lg:pr-8 my-auto">
                    Información siempre al alcance 
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Ofrece  información más completa al huésped" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 lg:pr-8 my-auto">
                    Reduce preguntas repetitivas
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Personaliza la información de tu hotel según tus necesidades y preferencias" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Implementación llave en mano
                </p>
            </div>
        </section>
    
        <section id="ih-offer" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block" src="/img/directorio-1-fix.jpg" alt="Ofrece toda la información de tu hotel ">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Beneficios para todos
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Los huéspedes disfrutan de una experiencia más fluida y satisfactoria, teniendo siempre a mano toda la información necesaria para aprovechar al máximo su estancia. Por otro lado, el personal del hotel ve reducida su carga de trabajo, evitando las preguntas frecuentes y repetitivas, y facilitando una gestión más eficiente y actualizada de la información del hotel. Este enfoque win-win mejora la satisfacción del cliente y optimiza las operaciones diarias del hotel.
                </p>
            </div>
            <img class="image-landing lg:hidden mt-8" src="/img/directorio-1-fix.jpg" alt="Ofrece toda la información de tu hotel ">
            
        </section>
    
        <section id="ih-easy" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing mr-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Intuitivo y fácil
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con la sencillez de la WebApp, tus huéspedes tendrán acceso fácil y rápido a toda la información necesaria durante su estancia en tu hotel. En la plataforma podrán encontrar:
                </p>
                <div class="lg:mt-8 flex flex-col lg:flex-row lg:justify-between">
                    <div class="lg:w-60">
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Perfil del hotel
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Encontrarán información imprescindible sobre el hotel, como su ubicación, horarios de check-in y check-out, número de habitaciones, etc.
                        </p>
                    </div>
                    <div class="lg:w-60">
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Instalaciones
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Guía detallada de todos los rincones del hotel, incluyendo descripciones pormenorizadas, galería de imágenes y horarios de apertura
                        </p>
                    </div>
                </div>
            </div>
            <img class="image-landing mt-8 lg:mt-auto lg:mb-auto hidden-xl" src="/img/directorio-2.jpg" alt="Intuitivo y fácil">
            <img class="image-landing mt-8 lg:mt-auto lg:mb-auto show-xl" src="/img/directorio-2-3x.jpg" alt="Intuitivo y fácil">
        </section>
    
        <section id="ih-management" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing lg:my-auto hidden lg:block hidden-xl" src="/img/directorio-3.jpg" alt="Listo para usar, fácil de gestionar">
            <img class="image-landing mr-landing lg:my-auto hidden lg:block show-xl" src="/img/directorio-3-3x.jpg" alt="Listo para usar, fácil de gestionar">
            <div class="info-landing lg:my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug pr-16 lg:pr-20">
                    Listo para usar, fácil de gestionar
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Con Directorio te ofrecemos una solución "llave en mano" para presentar toda la información esencial de tu hotel: una plataforma personalizada, lista para compartirla con tus huéspedes, en la que podrán consultar el perfil e instalaciones de tu alojamiento desde el primer momento. <br><br>
                    Directorio es intuitivo y fácil de gestionar, permitiéndote actualizar la información rápidamente y sin complicaciones, para que puedas centrarte en lo que realmente importa: la satisfacción de tus huéspedes. Además, siempre tendrás la posibilidad de modificar la información y mantenerla actualizada.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/directorio-3.jpg" alt="Listo para usar, fácil de gestionar">
        </section>
    
        <section id="repu-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
    import Layout from '@/layouts/MainLayout.vue'
    import ExperienceInfo from './components/ExperienceInfo.vue'
    import { useRouter } from 'vue-router';

    const router = useRouter();
    function contact_us () {
        router.push({ name: 'contact' });
    }
    
    </script>
    