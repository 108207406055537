<template>
    <Layout>
        <section id="creviews-banner" class="flex flex-col lg:flex-row mt-16 lg:mt-0">
            <div class="info-landing mr-landing">
                <h1 class="text-3xl lg:text-64 font-medium leading-snug w-[328px] mt-6 lg:mt-4 lg:w-auto">
                    Maximiza las reseñas positivas
                </h1>
                <p class="text-base lg:text-[22px] font-medium mt-8 lg:leading-8">
                    El 93% de los viajeros considera las reseñas online antes de elegir un alojamiento. Asegura valoraciones positivas y destaca entre la competencia.
                </p>
                <button @click="contact_us"  class="mt-8 lg:mt-12 hbtn-cta w-full md:w-44 h-14 rounded-lg text-base font-medium">
                    Te llamamos
                </button>
                <p class="text-sm font-semibold mt-6">Comienza con 14 días de prueba gratuita</p>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/creviews-banner.jpg" alt="Maximiza las reseñas positivas">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/creviews-banner-3x.jpg" alt="Maximiza las reseñas positivas">
        </section>
    
        <section id="creviews-interest" class="interest flex flex-col lg:flex-row items-center mt-16 lg:mt-20">
            <div class="w-full lg:w-1/3 flex">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Automatiza la petición de reseñas" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl px-3.5 lg:px-8 my-auto">
                    Automatiza la petición de reseñas
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Aumenta la nota media en OTAs" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Aumenta la nota media en OTAs
                </p>
            </div>
            <div class="w-full lg:w-1/3 flex mt-8 lg:mt-0">
                <img class="my-auto" src="/img/icons/green-border-left.svg" alt="Maximiza la visibilidad y reputación de tu hotel" style="width: 4px;height: 104px;">
                <p class="font-medium text-lg lg:text-2xl pl-3.5 lg:pl-8 my-auto">
                    Maximiza la visibilidad y reputación de tu hotel
                </p>
            </div>
        </section>
    
        <section id="creviews-section-1" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/creviews-1.jpg" alt="Mejores reseñas, mayor credibilidad">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/creviews-1-3x.jpg" alt="Mejores reseñas, mayor credibilidad">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Mejores reseñas, mayor credibilidad
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Una nota alta respaldada por un mayor número de reseñas eleva tu posición en los rankings y te consolida como una opción confiable y preferente para futuros clientes, traduciéndose en más reservas y huéspedes satisfechos. <br> <br>
                    Facilita el camino para que tus huéspedes compartan sus excelentes experiencias y construye una reputación sólida.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/creviews-1.jpg" alt="Mejores reseñas, mayor credibilidad">
        </section>
    
        <section id="creviews-section-2" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <div class="info-landing lg:my-auto mr-landing">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Automatiza la solicitud de reseñas
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    Simplifica el proceso y conecta directamente con las principales OTAs. Aquí algunas características destacadas:
                </p>
                <ul class="list-decimal mt-6 pl-4">
                    <li class="text-sm lg:text-base font-medium leading-6 lg:leading-7">Encuesta post-estancia: Al finalizar su estancia, tus huéspedes recibirán automáticamente una encuesta para conocer su nivel de satisfacción.</li>
                    <li class="text-sm lg:text-base font-medium leading-6 lg:leading-7">Segmentación de feedback: Según las respuestas obtenidas, se activarán diferentes estrategias para fomentar las reseñas positivas.</li>
                    <li class="text-sm lg:text-base font-medium leading-6 lg:leading-7">Redirección a OTAs: Guiamos a los huéspedes satisfechos directamente hacia las principales OTAs, facilitando así el camino para que dejen sus valoraciones positivas.</li>
                </ul>
                <div class="mt-6 lg:mt-8 grid grid-cols-1 lg:grid-cols-2 lg:hidden">
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium">
                            Encuesta post-estancia
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Tras la estancia, presenta una sencilla encuesta para medir el nivel de satisfacción de tus huéspedes.
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6 lg:mt-0">
                            Segmentación de feedback
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Según la respuesta a la encuesta, se activan diferentes flujos de acción para maximizar las reseñas positivas.
                        </p>
                    </div>
                    <div>
                        <h3 class="text-lg lg:text-[22px] font-medium mt-6">
                            Redirección a OTAs
                        </h3>
                        <p class="text-sm lg:text-base font-medium mt-4">
                            Facilita a tus huéspedes el camino para dejar sus valotaciones en las OTAs
                        </p>
                    </div>
                </div>
            </div>
            <img class="image-landing mt-8 lg:mt-auto mb-auto hidden-xl" src="/img/creviews-2.jpg" alt="Automatiza la solicitud de reseñas">
            <img class="image-landing mt-8 lg:mt-auto mb-auto show-xl" src="/img/creviews-2-3x.jpg" alt="Automatiza la solicitud de reseñas">
        </section>
    
        <section id="creviews-section-3" class="flex flex-col lg:flex-row mt-16 lg:mt-20">
            <img class="image-landing mr-landing my-auto hidden lg:block hidden-xl" src="/img/creviews-3.jpg" alt="Relájate y observa">
            <img class="image-landing mr-landing my-auto hidden lg:block show-xl" src="/img/creviews-3-3x.jpg" alt="Relájate y observa">
            <div class="info-landing my-auto">
                <h1 class="tx22 lg:text-40 font-medium leading-8 lg:leading-snug">
                    Relájate y observa
                </h1>
                <p class="text-sm lg:text-base font-medium mt-8">
                    La captación de reseñas es completamente automática: al finalizar la estancia de cada huésped se activa el proceso de solicitud de reseña. No tendrás que preocuparte por recordatorios o seguimientos manuales, simplemente, espera y observa cómo las reseñas comienzan a reflejarse en las OTAs.
                </p>
            </div>
            <img class="image-landing mt-8 lg:hidden" src="/img/creviews-3.jpg" alt="Relájate y observa">
        </section>
    
        <section id="creviews-experience" class="mt-16 lg:mt-40 lg:flex ">
            <ExperienceInfo />
        </section>
    </Layout>
    </template>
    
    <script setup>
        import Layout from '@/layouts/MainLayout.vue'
        import ExperienceInfo from './components/ExperienceInfo.vue'
        import { useRouter } from 'vue-router';

        const router = useRouter();
        function contact_us () {
            router.push({ name: 'contact' });
        }
    
    </script>